declare global {
  interface Window {
    grecaptcha: any;
  }
}

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, { FormEvent, useEffect, useRef, useState } from 'react';
import { ComponentClassType } from '../../interfaces/enums';
import { ComponentViewModel } from '../../interfaces/models';
import TextBlock from '../TextBlock';
import InputRadioStylized from './FieldFormComponents/InputRadioStylized';
import InputSubmitComponent from './FieldFormComponents/InputSubmitComponent';
import { getGenericParams } from '../../utils/utils';
import { candidatePost, contactUsPost } from '../../services/ViewServices';
import InputTextComponent from './FieldFormComponents/InputTextComponent';
import InputTextAreaComponent from './FieldFormComponents/InputTextAreaComponent';
import AppSettingsService from '../../services/AppSettingsService';
import { validateAndSendForm } from '../../services/cms/recaptchaService';
import InputCheckboxComponent from './FieldFormComponents/InputCheckboxComponent';
import InputFileComponent from './FieldFormComponents/InputFileComponent';
import { Container } from '@mui/system';
interface FormProps {
  form: ComponentViewModel;
}

const FormComponent: React.FC<FormProps> = ({ form }: FormProps) => {
  // let inputValues = new Map<string,string>();

  const [FormHasErrors, setFormHasErrors] = useState<boolean>(true);
  const [lastNameInput, setLastNameInput] = useState<string>('');
  const [firstNameInput, setNameInput] = useState<string>('');
  const [emailInput, setEmailInput] = useState<string>('');
  const [phoneInput, setPhoneInput] = useState<string>(' ');
  const [jobInput, setJobInput] = useState('');
  const [descriptionInput, setDescriptionInput] = useState<string>('');
  const [cvInput, setCvInput] = useState('');
  const [companyInput, setCompanyInput] = useState('');
  const [countryInput, setCountryInput] = useState('');
  const [open, setOpen] = useState(false);
  const [clear, setClear] = useState(false);
  const [formatInputValid, setFormatInputValid] = useState(true);
  const [Padding, setPadding] = useState('');
  const [BackgroundColor, setBackgroundColor] = useState('');
  const [HideCaptcha, setHideCaptcha] = useState(false);
  const [ConfirmationMessage, setConfirmationMessage] = useState(['']);
  const [TargetForm, setTargetForm] = useState('');
  const [codeNumber, setCodeNumber] = useState('');
  const loadReCaptcha = async () => {
    let siteKey = await AppSettingsService.getInstance().getReCaptchaSiteKey();
    let apiScript = await AppSettingsService.getInstance().getRecaptchaApiScriptUrl();
    let existsRecaptcha = document.getElementById('recaptcha-script');
    if (!existsRecaptcha) {
      const script = document.createElement('script');
      script.id = 'recaptcha-script';
      script.src = apiScript + siteKey;
      document.body.appendChild(script);
    }
  };

  const removeRecaptcha = () => {
    let existsRecaptcha = document.getElementById('recaptcha-script');
    if (existsRecaptcha) {
      let uiRecaptcha = document.getElementsByClassName('grecaptcha-badge');
      if (uiRecaptcha.length > 0) {
        let firtsGrecaptcha = uiRecaptcha[0];
        let parentUIRecaptcha = firtsGrecaptcha.parentElement;
        if (parentUIRecaptcha) {
          document.body.removeChild(parentUIRecaptcha);
        }
      }
      document.body.removeChild(existsRecaptcha);
    }
  };

  useEffect(() => {
    form.parameters.forEach((parameter) => {
      if (parameter.type === 'BackgroundColor') {
        setBackgroundColor(parameter.value);
      } else if (parameter.type === 'Padding') {
        setPadding(parameter.value);
      } else if (parameter.type === 'HideCaptcha') {
        let hideCaptchaValue = parameter.value.toLowerCase() === 'true' ? true : false;
        setHideCaptcha(hideCaptchaValue);
        if (!hideCaptchaValue) {
          loadReCaptcha();
        } else {
          removeRecaptcha();
        }
      } else if (parameter.type === 'ConfirmationMessage') {
        setConfirmationMessage(parameter.value.split('\n'));
      } else if (parameter.type === 'TargetForm') {
        setTargetForm(parameter.value.toLowerCase());
      }
    });
    evaluateInputs();
  }, [form.parameters]);

  useEffect(() => {
    checkinputRequires();
  }, [clear]);

  useEffect(() => {
    evaluateInputs();
  }, [
    phoneInput,
    countryInput,
    lastNameInput,
    firstNameInput,
    emailInput,
    jobInput,
    descriptionInput,
    cvInput,
    companyInput,
  ]);

  const theme = useTheme();
  const largeScreen = useMediaQuery(theme.breakpoints.up('md'));

  const checkinputRequires = () => {
    let listRequires = [' '];
    form.children.forEach((component) => {
      let requiredParemeter = ' ';
      let nameParameter = ' ';
      component.parameters.forEach((parameter) => {
        if (parameter.type === 'Required') {
          requiredParemeter = parameter.value;
        } else if (parameter.type === 'Name') {
          nameParameter = parameter.value;
        }
      });
      if (requiredParemeter.toLowerCase() === 'true') {
        listRequires.push(nameParameter);
      }
    });
    SetInputNotRequires(listRequires);
    EmailIsInputRequired(listRequires);
  };

  const EmailIsInputRequired = (listRequires: string[]) => {
    listRequires.forEach((element) => {
      if (element === 'emailInput') {
        setFormatInputValid(false);
      }
    });
  };

  const SetInputNotRequires = (inputsRequireds: string[]) => {
    let nameOfAllFields = [
      'nameInput',
      'lastNameInput',
      'emailInput',
      'jobInput',
      'countryInput',
      'companyInput',
      'phoneInput',
      'cvInput',
      'descriptionInput',
    ];

    let inputNotRequireds = nameOfAllFields.filter((name) => {
      return !inputsRequireds.includes(name);
    });

    inputNotRequireds.forEach((fieldName) => {
      if (fieldName === 'nameInput') {
        setNameInput(' ');
      } else if (fieldName === 'lastNameInput') {
        setLastNameInput(' ');
      } else if (fieldName === 'emailInput') {
        setEmailInput(' ');
      } else if (fieldName === 'jobInput') {
        setJobInput(' ');
      } else if (fieldName === 'countryInput') {
        setCountryInput(' ');
      } else if (fieldName === 'companyInput') {
        setCompanyInput(' ');
      } else if (fieldName === 'phoneInput') {
        setPhoneInput(' ');
      } else if (fieldName === 'cvInput') {
        setCvInput(' ');
      } else if (fieldName === 'descriptionInput') {
        setDescriptionInput(' ');
      }
    });
  };
  const renderTypeField = () => {
    let change = true;
    let itemsInlined = form.children.filter((component) => {
      return getGenericParams(component).Inlined === 'true';
    });
    return form.children.map((component) => {
      return component.type === ComponentClassType.InputRadioStylized ? (
        <InputRadioStylized
          key={'form-input-radio' + component.id}
          clear={clear}
          Parameters={component.parameters}
          getValue={updateInputComponent}
        />
      ) : component.type === ComponentClassType.InputTextComponent &&
        getGenericParams(component).Inlined?.toLowerCase() === 'true' &&
        change ? (
      
        <Grid
          key={'Form-input-textComponent-inlined' + component.id}
          container
          direction={largeScreen ? 'row' : 'column'}
          justifyContent="center"
          alignItems="center"
          alignContent="center"
          spacing={largeScreen ? 6 : 0}
          wrap="wrap"
          id={'form-component-container-inlined-' + form.key}
        >
          {itemsInlined.map((component) => {
            change = false;
            return component.type === ComponentClassType.InputTextComponent ? (
              <InputTextComponent
                key={'form-' + component.id}
                clear={clear}
                idParent={form.key}
                Parameters={component.parameters}
                getValue={updateInputComponent}
                codePhone={codeNumber}
              />
            ) : component.type === ComponentClassType.InputSubmitComponent ? (
              <InputSubmitComponent
                key={'form-' + component.id}
                Parameters={component.parameters}
                formHasErrors={FormHasErrors}
              />
            ) : null;
          })}
        </Grid>
      
      ) : component.type === ComponentClassType.InputTextComponent &&
        getGenericParams(component).Inlined?.toLowerCase() !== 'true' ? (
       
        <Grid
          container
          key={'form-input-textComponent-' + component.id}
          direction={{xs:'column',md:'row',lg:'row'}}
          alignItems="center"
          justifyContent="center"
          spacing={largeScreen ? 6 : 0}
        >
          <InputTextComponent
            idParent={form.key}
            clear={clear}
            Parameters={component.parameters}
            getValue={updateInputComponent}
            codePhone={codeNumber}
          />
        </Grid>
      
      ) : component.type === ComponentClassType.InputTextAreaComponent ? (
        <Grid
          container
          key={'form-input-' + component.id}
          direction={{xs:'column',md:'row',lg:'row'}}
          alignItems="center"
          justifyContent="center"
          spacing={largeScreen ? 6 : 0}
        >
          <InputTextAreaComponent
            key={'form-' + component.id}
            idParent={form.key}
            Parameters={component.parameters}
            getValue={updateInputComponent}
            clear={clear}
          />
        </Grid>
      ) : component.type === ComponentClassType.InputFileComponent ? (
        <Grid
          container
          key={'form-input-' + component.id}
          direction="row"
          alignItems="center"
          justifyContent="center"
          spacing={largeScreen ? 6 : 0}
        >
          <InputFileComponent
            key={'form-' + component.id}
            idParent={form.key}
            Parameters={component.parameters}
            getValue={updateInputComponent}
            clear={clear}
          />
        </Grid>
      ) : component.type === ComponentClassType.InputCheckboxComponent ? (
        <Grid
          container
          key={'form-input-' + component.id}
          direction="row"
          alignItems="center"
          justifyContent="center"
          spacing={largeScreen ? 6 : 0}
        >
          <InputCheckboxComponent
            key={'form-' + component.id}
            idParent={form.key}
            Parameters={component.parameters}
            getValue={updateInputComponent}
            clear={clear}
            items={component.children}
            setPhone={setCodeNumber}
          />
        </Grid>
      ) : component.type === ComponentClassType.TextBlock ? (
        <TextBlock key={'form-' + component.id} paramArray={component.parameters} />
      ) : null;
    });
  };
  const updateInputComponent = (name: string, value: string, format: boolean) => {
    setFormatInputValid(format);
    if (name === 'jobInput') {
      setJobInput(value);
    } else if (name === 'nameInput') {
      setNameInput(value);
    } else if (name === 'lastNameInput') {
      setLastNameInput(value);
    } else if (name === 'emailInput') {
      setEmailInput(value);
    } else if (name === 'descriptionInput') {
      setDescriptionInput(value);
    } else if (name === 'companyInput') {
      setCompanyInput(value);
    } else if (name === 'phoneInput') {
      setPhoneInput(value);
    } else if (name === 'cvInput') {
      setCvInput(value);
    } else if (name === 'countryInput') {
      setCountryInput(value);
    }
  };
  const evaluateInputs = () => {
    let res = false;
    if (formatInputValid) {
      if (
        firstNameInput === '' ||
        emailInput === '' ||
        jobInput === '' ||
        lastNameInput === '' ||
        descriptionInput === '' ||
        countryInput === '' ||
        phoneInput === '' ||
        cvInput === '' ||
        companyInput === ''
      ) {
        res = true;
      }
    } else {
      res = true;
    }
    setFormHasErrors(res);
  };

  const renderInputSubmit = () => {
    let inputSubmit = form.children.find((component) => {
      return component.type === ComponentClassType.InputSubmitComponent ? component : null;
    });

    return inputSubmit ? (
      getGenericParams(inputSubmit).Inlined?.toLowerCase() === 'true' ? null : (
        <InputSubmitComponent Parameters={inputSubmit.parameters} formHasErrors={FormHasErrors} />
      )
    ) : null;
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (HideCaptcha) {
      saveForm('');
    } else {
      await validateAndSendForm(saveForm);
    }
  };

  const saveForm = (token: string) => {
    if (!FormHasErrors) {
      const resetForm = () => {
        setNameInput('');
        setLastNameInput('');
        setCvInput('');
        setEmailInput('');
        setJobInput('');
        setFormatInputValid(false);
        setDescriptionInput('');
        setCompanyInput('');
        setCountryInput('');
        setPhoneInput('');
        clear ? setClear(false) : setClear(true);
      };

      if (TargetForm === 'candidate') {
        candidatePost(
          {
            firstNameInput,
            lastNameInput,
            emailInput,
            phoneInput,
            jobInput,
            cvInput,
            descriptionInput,
          },
          {
            token: token,
            hideCaptcha: HideCaptcha,
          },
          resetForm,
        );
      } else if (TargetForm === 'contact') {
        contactUsPost(
          {
            firstNameInput,
            lastNameInput,
            emailInput,
            phoneInput,
            countryInput,
            companyInput,
            descriptionInput,
          },
          {
            token: token,
            hideCaptcha: HideCaptcha,
          },
          resetForm,
        );
      }
      handleClickOpen();
    }
  };

  return (
    <div className="careers-form" style={{ padding: Padding, backgroundColor: BackgroundColor }}>
      <Grid className="form-grid" container spacing={1}>
        <Grid item xs={12} lg={12} className="form-grid-item">
          <form className="form-body" onSubmit={(e: FormEvent) => handleSubmit(e)}>
            {renderTypeField()}
            {renderInputSubmit()}
          </form>
          <Dialog open={open} onClose={handleClose} aria-describedby="alert-dialog-description">
            <DialogContent>
              {ConfirmationMessage.map((message, index) => {
                return (
                  <DialogContentText
                    key={index}
                    id="alert-dialog-description"
                    style={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}
                  >
                    {message.replaceAll('\\n', '')}
                  </DialogContentText>
                );
              })}
            </DialogContent>
            <DialogActions>
              <Button name="okButton" onClick={handleClose} color="primary">
                Ok
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>
      </Grid>
      <style>
         {`
          .grecaptcha-badge {
            bottom: 60px !important; 
          }
        `}
      </style>
    </div>
  );
};

export default FormComponent;
